// extracted by mini-css-extract-plugin
export var aArrow1 = "Tc";
export var aArrow2 = "Uc";
export var aEmpty = "Jc";
export var aLeft = "Yc";
export var aRight = "_c";
export var aTagAnnounce = "Qc";
export var aTagClose = "Pc";
export var aTagEntry = "Nc";
export var aTagFree = "Sc";
export var aTagLottery = "Rc";
export var aTagPayment = "Oc";
export var aText = "Vc";
export var aTitle = "Kc";
export var aWrap = "Xc";
export var animationMarquee = "bd";
export var infinityScrollLeft = "Zc";
export var infinityScrollRight = "ad";
export var pItemBatch = "Lc";
export var pItemBatchAbs = "Mc";
export var pSlideItemList = "Wc";